import Api from './Api'
import queryMiddleware from '../middleware/query-builder'
export default {

  getPosts(filter){
    const query = queryMiddleware.builder(filter)
    return Api().get(`posts?${query}`)
  },

}
