<template>
  <div class="body">
    <v-app>
      <PageHeader/>
      <v-main v-if="post" class="full-article">
        <div class="text-center py-5">
          <h1>{{ post.title }}</h1>
          <br/>
          <h3>{{ post.author }}</h3>
          <p>Posted {{ post.createdAtFixed }}</p>
          <div class="ql-editor body-body" v-html="post.body"></div>
          <v-img  max-height="25em" class="ma-lg-12 ma-sm-4 mb-7 " v-if="post.imageURL != null" :src="post.imageURL" aspect-ratio="2"></v-img>
        </div>
      </v-main>
      <Footer/>
    </v-app>
  </div>
</template>
<script>
import PageHeader from "@/components/Header";
import Footer from "@/components/Footer";
import Posts from "../services/Posts";
import moment from "moment";
export default {
  name: "FullArticle",
  components: {PageHeader, Footer},
  props: ['post'],
  data() {
    return {};
  },
  async mounted() {
    if (!this.post) {
      if (this.$route.query.id) {
        const postId = this.$route.query.id;
        this.post = (await Posts.getPosts([
          {
            name: "postid",
            value: postId,
          },
          {
            name: "limit",
            value: 1,
          }

        ])).data[0];
        this.post.createdAtFixed = moment(this.post.createdAt).format("LL");
      } else {
        await this.$router.push({
          name: "Home",
        });
      }
    }

  },
  methods: {}
};
</script>
<style>
@import "//cdn.quilljs.com/1.3.6/quill.core.css";
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital@1&display=swap');
.full-article {
  font-family: 'Poppins', sans-serif;
  background-color: #f1eff2;
}

@media screen and (min-width: 1000px) {
  .body-body {
    padding-right: 15%;
    padding-left: 15%
  }
}



</style>