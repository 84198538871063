export default {
    builder(filter) {

        const parts = [];
        filter.map(filter => {
            if (filter.value != null && filter.value !== '' && filter.value !== '%null%' && filter.value !== '%%' && filter.value !== 'null') {
                parts.push(`${filter.name}=${filter.value}&`);
            }
            return parts
        })
        if(parts.length === 0){
            return ''
        }
        if (parts.length > 0) {
            const line = parts.toString()
            const line2 = line.slice(0, -1);
            const query = line2.replace(/,/g, '');
            return query || ''
        }

    },
}
